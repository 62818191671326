<template>
  <div id="">
    <b-loading :is-full-page="true" v-model="loading" />
    <Header />
    <div class="h-24"></div>
    <FloatingBreadcrumbs :data="[]" />
    <transition
      enter-active-class="animate__animated animate__fadeInDown animate__faster"
      leave-active-class="animate__animated animate__fadeOutUp animate__faster"
    >
      <div
        v-if="sticky && !loading"
        :class="{
          sticky: sticky
        }"
      >
        <FloatingBreadcrumbs :data="[]" />
      </div>
    </transition>
    <div class="container p-4 pt-0">
      <div class="px-2">
        <div class="-mx-4 flex flex-wrap">
          <div
            class="w-1/4 mb-4 px-2"
            v-for="(item, index) in products"
            :key="index"
          >
            <ProductCard :data="item" />
          </div>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeInUp animate__faster"
      leave-active-class="animate__animated animate__fadeOutDown animate__faster"
    >
      <div
        v-if="products.length && products.length % 4 == 0 && page <= totalPages"
        @click="getMoreProducts"
        class="more fixed bottom-0 bg-gray-200 bg-opacity-50 h-16 flex items-center justify-center w-full has-text-gold cursor-pointer is-uppercase"
      >
        Click or Scroll for More Products
      </div>
    </transition>
    <div ref="end"></div>
  </div>
</template>

<script>
import Header from "@/components/client/Header";
import FloatingBreadcrumbs from "@/components/client/FloatingBreadcrumbs";
import ProductCard from "@/components/client/ProductCard";
import store from "@/store";
export default {
  title() {
    return `Products - ${process.env.VUE_APP_TITLE}`;
  },
  data: () => ({
    loading: false,
    products: [],
    page: 1,
    totalPages: 1,
    sticky: false,
    previousScroll: 0
  }),
  components: {
    Header,
    FloatingBreadcrumbs,
    ProductCard
  },
  watch: {
    sticky() {
      if (this.sticky) {
        setTimeout(() => {
          this.sticky = false;
        }, 5000);
      }
    },
    $route() {
      this.loading = true;
      this.previousScroll = 0;
      this.page = 1;
      this.totalPages = 1;
      store
        .dispatch(this.$route.meta.dispatch, {
          search: this.$route.query.search
        })
        .then(res => {
          this.products = res.data.data;
          this.page++;
          this.totalPages = res.data.last_page;
          // this.totalPages = res.data.data.length ? this.page + 1 : 1;
        })
        .catch(() => {
          this.$router.push({ name: "Home" });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    getMoreProducts: _.debounce(function() {
      if (this.page <= this.totalPages) {
        this.loading = true;
        this.page++;
        store
          .dispatch(this.$route.meta.dispatch, {
            search: this.$route.query.search,
            page: this.page
          })
          .then(res => {
            res.data.data.forEach(item => this.products.push(item));
            // this.page++;
            // this.totalPages = res.data.data.length ? this.page + 1 : 1;
            this.totalPages = res.data.last_page;
            this.loading = false;
          });
      }
    }, 250),
    onScroll() {
      if (_.ceil(document.documentElement.scrollTop) > 50) {
        var currentScroll = _.ceil(document.documentElement.scrollTop);
        if (this.previousScroll > currentScroll && !this.loading) {
          this.sticky = true;
        }
      }
      this.previousScroll = currentScroll;

      if (
        document.documentElement.scrollHeight -
          _.ceil(document.documentElement.scrollTop) <=
        window.innerHeight
      ) {
        this.getMoreProducts();
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch && to.query.search) {
      store.dispatch(to.meta.dispatch, to.query).then(res => {
        next(vm => {
          vm.products = res.data.data;
          vm.totalPages = res.data.last_page;
        });
      });
    } else {
      next({ name: "Home" });
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.onScroll);
    next();
  }
};
</script>

<style lang="scss" scoped>
#scrollable {
  max-height: 100vh;
  overflow: scroll;
}
</style>
